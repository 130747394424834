import React from 'react'
import LanguageSwitch from '@designSystem/LanguageSwitch'
import styled, { x } from '@xstyled/styled-components'
import Logo from '@mailstep/design-system/ui/Elements/Logo'
import { H7 } from '@mailstep/design-system/ui/Elements/Typography'
import { Trans } from '@lingui/react'

const LanguageBox = styled(x.div)`
  display: flex;
  margin: 15px 0;
  align-items: flex-end;
  justify-content: space-between;
  @media (min-width: 1024px) {
    justify-content: flex-end;
    margin: 40px 0;
  }
`

const CardBodyWithLanguage = styled.div`
  display: flex;
  justify-content: start;
  padding: 0 32px 10px 32px;
  flex-grow: 1;
  margin: auto;
  width: 500px;
  @media (min-width: 1024px) {
    display: block;
    flex-grow: 0;
  }
`

const MailTo = styled.a`
  color: typoPrimary;
  &:hover {
    color: red1;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  @media (min-width: 1024px) {
    display: block;
    matgin-top: 0;
  }
`

const MainLogo = styled(Logo)`
  width: 50%;
  height: auto;
`

type Props = {
  children: React.ReactNode
  activeLanguage?: string
  onChange: (value: string) => void
}

const LanguageCardBody = ({ children, activeLanguage = '', onChange }: Props): JSX.Element => {
  return (
    <CardBodyWithLanguage>
      <LogoWrapper>
        <MainLogo variant="s" />
      </LogoWrapper>
      <LanguageBox>
        <x.div mr={4}>
          <H7 color="gray1" variant="bold" marginRight={1}>
            <Trans id="form.heading.support" message="Support:" />
          </H7>
          <MailTo href="mailto:mailstep@mailstep.cz">mailstep@mailstep.cz</MailTo>
        </x.div>
        <LanguageSwitch activeLanguage={activeLanguage} onChange={onChange} />
      </LanguageBox>
      {children}
    </CardBodyWithLanguage>
  )
}

export default LanguageCardBody
